
.modal-backdrop {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
}

.modal-content-wrapper {
    position: fixed;
    width: 450px;
    height: 450px;
    background: #fff;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.modal-header {
    height: 45px;
    line-height: 45px;
    padding-left: 5px;
    text-align: left;
    color: #fff;
    background-color: #410088;
    font-size: 1.3rem;
    font-weight: 400;
}

.modal-content {
    padding: 10px;
    text-align: left;
}
.modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    border-top: 1px solid #ccc;
    background-color: #ccc;
}

.form-controls{
    margin-bottom: 0.3rem;
}

.react-datepicker-wrapper {
    margin-left: 0 !important;
}