
  .login-form {
    position: fixed;
    height: 100vh;
    width: 100vw;
    color: #404040;
    background: #0ca3d2;
  }
  
  .login {
    position: relative;
    margin: 30px auto;
    padding: 20px 20px 20px;
    width: 310px;
    background: white;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 200px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 200px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.3);
  }
  
  .login:before {
    content: '';
    position: absolute;
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
    z-index: -1;
    background: rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }
  
  .login h1 {
    margin: -20px -20px 21px;
    height: 60px;
    line-height: 60px;
    font-size: 1.4rem;
    font-weight: 400;
    color: #555;
    text-align: center;
    text-shadow: 0 1px white;
    background: #f3f3f3;
    border-bottom: 1px solid #cfcfcf;
    border-radius: 3px 3px 0 0;
    background-image: -webkit-linear-gradient(top, whiteffd, #eef2f5);
    background-image: -moz-linear-gradient(top, whiteffd, #eef2f5);
    background-image: -o-linear-gradient(top, whiteffd, #eef2f5);
    background-image: linear-gradient(to bottom, whiteffd, #eef2f5);
    -webkit-box-shadow: 0 1px whitesmoke;
    box-shadow: 0 1px whitesmoke;
  }
  
  .login-help {
    margin: 20px 0;
    font-size: 0.9rem;
    color: white;
    text-align: center;
    text-shadow: 0 1px #2a85a1;
  }
  
  .login-help a {
    color: #cce7fa;
    text-decoration: none;
  }
  
  .login-help a:hover {
    text-decoration: underline;
  }