
.center-draggable-wrapper{
    /* position:absolute;
    top:65px;
    left: 55px; */
    min-width: 650px;
    height: 480px;
    background-color: #f4f4f4;
    color: #000;
    z-index: 4500;
}

.center-draggable-content{
    position: relative;
}

.center-draggable-header{
    position: relative;
    margin: 0;
    padding: 0 10px;
    padding-right: 0;
    background-color: darkslateblue;
    color: #fff;
    cursor: grab;
    text-align: left;
    height: 45px;
    line-height: 45px;
}
.center-draggable-header h3{
    padding: 0;
    margin: 0;
}
.btn-close-draggable-wrapper{
    position: absolute;
    top: 0px;
    right: 0px;
    width: 45px;
    height: 45px;
    cursor: pointer;
    background-color:#7c71b9;
    color: #fff;
    text-align: center;
    line-height: 45px;
    font-size: 1.6rem;
    font-weight: 400;
}

.btn-close-draggable-wrapper:hover{
    background-color: #5c4cb3;
}

.report-footer{
    position:absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 45px;
    background-color: #efefef;
    color: #000;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
}

.draggable-content-toolbar{
    padding: 10px;
    text-align: left;
    background-color: #e3deffff;
}

.loading-wrapper{
    margin-top: 25px;
}
.active-status-wrapper {
    position:absolute;
    right: 10px;
    top:5px
}